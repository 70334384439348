<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
    <Button label="Actualizar" icon="pi pi-save" type="submit" form="formLici"/>
  </div>

  <Accordion :multiple="true" :activeIndex="[0,1]">
    <AccordionTab header="Licitacion">
      <div class="grid lg:grid-cols-2 my-2 gap-4">
        <!--  formulario  -->
        <div class="bg-white p-4 rounded-3xl">
          <div class="form-demo">
            <div class="flex justify-content-center">
              <div class="card w-full">
                <b class="text-center mb-5">
                  Licitacion
                </b>
                <form id="formLici" class="p-fluid" @submit.prevent="handleSubmit(!v$.$invalid)">
                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="nombre"
                                 v-model="v$.nombre.$model"
                                 :class="{'p-invalid':v$.nombre.$invalid && submitted}"/>
                      <label for="nombre"
                             :class="{'p-error':v$.nombre.$invalid && submitted}">
                        Nombre*
                      </label>
                    </div>
                    <small v-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Nombre es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label for="fechaApertura"
                             :class="{'p-error':v$.fechaApertura.$invalid && submitted}">
                        Fecha Apertura*
                      </label>
                      <InputText id="fechaApertura"
                                 type="date"
                                 v-model="v$.fechaApertura.$model"
                                 :class="{'p-invalid':v$.fechaApertura.$invalid && submitted}"/>
                    </div>
                    <small v-if="(v$.fechaApertura.$invalid && submitted) || v$.fechaApertura.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Fecha de apertura es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label for="fechaCierre"
                             :class="{'p-error':v$.fechaCierre.$invalid && submitted}">
                        Fecha Cierre*
                      </label>
                      <InputText id="fechaCierre"
                                 type="date"
                                 v-model="v$.fechaCierre.$model"
                                 :class="{'p-invalid':v$.fechaCierre.$invalid && submitted}"/>
                    </div>
                    <small v-if="(v$.fechaCierre.$invalid && submitted) || v$.fechaCierre.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Fecha de cierre es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="comentario"
                                 v-model="v$.comentario.$model"
                                 :class="{'p-invalid':v$.comentario.$invalid && submitted}"/>
                      <label for="comentario"
                             :class="{'p-error':v$.comentario.$invalid && submitted}">
                        Comentario*
                      </label>
                    </div>
                    <small v-if="(v$.comentario.$invalid && submitted) || v$.comentario.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Comentario es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="lugarEjecucion"
                                 v-model="v$.lugarEjecucion.$model"
                                 :class="{'p-invalid':v$.lugarEjecucion.$invalid && submitted}"/>
                      <label for="lugarEjecucion"
                             :class="{'p-error':v$.lugarEjecucion.$invalid && submitted}">
                        Lugar Ejecucion*
                      </label>
                    </div>
                    <small v-if="(v$.lugarEjecucion.$invalid && submitted) || v$.lugarEjecucion.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Lugar de ejecucion es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <Dropdown id="tipoRadicacion" v-model="v$.tipoRadicacion.$model" :options="tipoRadicaciones" optionValue="id" :class="{'p-invalid':v$.tipoRadicacion.$invalid && submitted}" optionLabel="name" />
                      <label for="tipoRadicacion"
                             :class="{'p-error':v$.tipoRadicacion.$invalid && submitted}">
                        Tipo Radicacion*
                      </label>
                    </div>
                    <small v-if="(v$.tipoRadicacion.$invalid && submitted) || v$.tipoRadicacion.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Tipo de radicacion es requerido" }}
                    </small>
                  </div>
                  <div class="field">
                    <div class="p-float-label">
                      <Dropdown id="estado"
                                v-model="v$.idEstado.$model"
                                :options="estadosList"
                                optionValue="id"
                                :class="{'p-invalid':v$.idEstado.$invalid && submitted}"
                                optionLabel="nombre"
                                :disabled="disabledListEstados"
                      />
                      <label for="estado"
                             :class="{'p-error':v$.idEstado.$invalid && submitted}">
                        Estado*
                      </label>
                    </div>
                    <small v-if="(v$.idEstado.$invalid && submitted) || v$.idEstado.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "El estado es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label>
                        Adjunto Pliegos*
                      </label>
                    </div>
                    <div class="flex gap-4">
                      <Button label="Ver Pdfs" @click="verListarPdfs" />
                    </div>
                    <input type="file"
                           ref="adjuntoPliegos"
                           id="adjuntoPliegos"
                           accept="application/pdf"
                           @change="onUpload($event.target)"
                           class="hidden"/>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputNumber id="valorPresupuesto" v-model="v$.valorPresupuesto.$model" mode="currency" currency="COP" locale="es-CO" :class="{'p-invalid':v$.valorPresupuesto.$invalid && submitted}"/>
                      <label for="valorPresupuesto"
                             :class="{'p-error':v$.valorPresupuesto.$invalid && submitted}">
                        Valor Presupuesto*
                      </label>
                    </div>
                    <small v-if="(v$.valorPresupuesto.$invalid && submitted) || v$.valorPresupuesto.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.valorPresupuesto.required.$message = "El valor del presupuesto es requerido" }}
                    </small>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- carpetas   -->
        <div class="bg-white p-4 rounded-3xl">
          <div class="card">
            <div class="flex justify-between mb-5">
              <b class="text-center">
                Carpetas
              </b>
              <Button icon="pi pi-plus" class="p-button-rounded" @click="modalAddFolder=true"/>
            </div>
            <DataTable :value="carpetas"
                       :reorderableColumns="true"
                       @rowReorder="onRowReorder"
                       responsiveLayout="scroll"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column :rowReorder="true"
                      headerStyle="width: 3rem"
                      :reorderableColumn="false"
              />
              <Column field="name" header="Nombre"></Column>
              <Column header="Acciones" headerStyle="width: 20%">
                <template #body="{data}">
                  <div class="flex justify-around">
                    <Button icon="pi pi-pencil"
                            class="mr-2"
                            style="height: 40px; width: 40px;"
                            @click="actionModalNameFolder(data)"
                            v-tooltip.top="'Editar Carpeta'"
                    />
                    <Button icon="pi pi-trash"
                            class="mr-2 p-button-danger"
                            style="height: 40px; width: 40px;"
                            @click="eliminarCarpeta(data)"
                            v-tooltip.top="'Eliminar Carpeta'"
                    />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab header="Documentos">
      <div class="bg-white rounded-3xl mt-2">
        <InputText type="text" v-model="filterText" placeholder="Filtro Documentos" class="mt-4 ml-4" @keyup="filterDocuments"/>
        <div class="grid grid-cols-2 gap-4">
          <div><!-- Primera Columna-->
            <div class="p-col-12">
              <Divider align="left" type="dashed">
                <b>Documentos</b>
              </Divider>
              <template v-if="documentsFiltered.length < 1">
                <span class="ml-1">No hay documentos para mostrar</span>
              </template>
              <draggable
                class="flex flex-col overflow-x-auto"
                :list="documentsFiltered"
                group="people"
                itemKey="name"
                style="height: 750px"
              >
                <template #item="{ element }">
                  <div class="ml-1">
                    <Tag icon="pi pi-file-pdf"
                         :value="element.nombre"
                         class="bg-white text-blue-900 cursor-pointer"
                    />
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div class="container p-col-12" style="height: 800px; overflow-x: auto"> <!-- Segunda Columna-->
            <Accordion multiple @tab-open="abrio" @tab-close="cerro" :activeIndex="activeIndex">
              <AccordionTab v-for="(carpeta, index) of carpetas" :key="index">
                <template #header>
                  <i class="pi pi-folder-open" :id="`myAcordion_${index}`" />
                  <span class="ml-2">{{ carpeta.name }}</span>
                </template>
                <draggable
                  class="list-group overflow-x-auto"
                  :list="carpeta.list"
                  group="people"
                  itemKey="name"
                  @change="log($event,carpeta.id)"
                >
                  <template #item="{ element, index }">
                    <div class="list-group-item">
                      <Tag icon="pi pi-file-pdf"
                           :value="(index+1) + ' - ' + element.nombre"
                           class="bg-white text-blue-900 my-1 cursor-pointer"
                           @click="verPdf(element.path)"
                      />
                    </div>
                  </template>
                </draggable>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </AccordionTab>
  </Accordion>

  <Dialog v-model:visible="modalAddFolder" :style="{width: '450px'}" header="Agregar Carpeta" :modal="true" class="p-fluid">
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name"
                 v-model.trim="nameFolder"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
                 :class="{'p-invalid': validationInput(submitted,nameFolder)}"
                 v-on:keyup.enter="saveModalAddFolder"
      />
      <small class="p-error"
             v-if="!submitted && !nameFolder"
      >
        Nombre es requerido
      </small>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="hideModalAddFolder"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveModalAddFolder"
      />
    </template>
  </Dialog>

  <Dialog v-model:visible="modalNameFolder" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Editar Carpeta">
    <div class="flex flex-col justify-center">
      <span>Nombre Carpeta</span>
      <InputText v-model="updateCarpeta.nombre" />
    </div>
    <template #footer>
      <Button label="No" @click="actionModalNameFolder"/>
      <Button class="p-button-success" label="Actualizar" @click="editarCarpeta" autofocus/>
    </template>
  </Dialog>

  <Dialog v-model:visible="modalListarPdfs" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '80vw'}" header="Lista Pdfs">
    <filePond ref="pond" :url="url" :licitacionId="route.params.id"/>
    <DataTable :value="listaPdfs"
               responsiveLayout="scroll"
               :paginator="true"
               :rows="5"
               class="p-datatable-sm"
    >
      <Column field="path" header="Pdf">
        <template #body="{data}">
          <span>{{ data.path.split('/')[2].split('@')[1] }}</span>
        </template>
      </Column>
      <Column field="fullName" header="Usuario"></Column>
      <Column header="Acciones" headerStyle="width: 25%">
        <template #body="{data}">
          <Button label="Ver Pdf" @click="verPdf(data.path)" />
          <Button label="Reemplazar Pdf" class="p-button-success ml-4" @click="reemplazarPdf(data)" />
          <Button label="Eliminar Pdf" class="p-button-danger ml-4" @click="eliminarPdf(data)"/>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <div class="flex justify-end">
        <Button label="Cargar"
                icon="pi pi-save"
                class="mr-2 p-button-success"
                @click="pondCargarPdf"
        />
        <Button label="Salir" class="p-button-danger" @click="modalListarPdfs = !modalListarPdfs"/>
      </div>
    </template>
  </Dialog>

  <Dialog v-model:visible="modalPdf" :style="{width: '80%'}" contentStyle="overflow: hidden" header="Ver documento" :modal="true" class="p-fluid">
    <div id="embedPdf" style="display: block">
      <embed :src="rutaConsolidado" frameborder="0"
             id="pdf"
             width="100%"
             height="750rem"
      />
    </div>
    <div id="cargando" style="display: none; justify-content: center; align-items: center">
      <ProgressSpinner />
    </div>
    <template #footer>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div class="w-3/4" style="display: flex; align-items: center">
          <Button label="Girar"
                  icon="pi pi-replay"
                  class="mr-2 p-button-warning"
                  @click="girarPdf"
          />
          <div v-for="item of giros" :key="item.key" class="field-radiobutton ml-2">
            <RadioButton :id="item.key" name="category" :value="item.key" v-model="giro" />
            <label :for="item.key">{{item.nombre}}</label>
          </div>
          <InputNumber v-model="pageNumber" placeholder="No. Pag." style="width: 20%; margin-left: 4px"/>
        </div>
      </div>
    </template>
  </Dialog>

  <input type="file"
         ref="nuevoPliego"
         id="nuevoPliego"
         accept="application/pdf"
         @change="onUploadNuevoPliego($event.target)"
         class="hidden"/>
  <Toast/>
</template>
<script>
import { ref, onMounted, reactive, defineAsyncComponent } from 'vue'
import { required, numeric } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import draggable from 'vuedraggable'
import Tag from 'primevue/tag'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import LicitacionesService from '../../services/LicitacionesService'
import DocumentService from '../../services/DocumentService'
import { useToast } from 'primevue/usetoast'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
  name: 'editLicitacion',
  components: {
    draggable,
    Tag,
    Accordion,
    AccordionTab,
    filePond: defineAsyncComponent(() => import('./uploadFilePound'))
  },
  setup () {
    const updateCarpeta = ref({})
    const modalNameFolder = ref(false)
    const adjuntoPliegos = ref()
    const nuevoPliego = ref()
    const carpetas = ref([])
    const route = useRoute()
    const router = useRouter()
    const filterText = ref('')
    const rutaPdf = ref('')
    const documents = ref([])
    const documentsFiltered = ref([])
    const estadosList = ref([])
    const disabledListEstados = ref(false)

    onMounted(() => {
      getEstados()
      Promise.all([
        getDocuments()
      ])
    })
    const toast = useToast()
    const _LicitacionesService = ref(new LicitacionesService())
    const _DocumentService = ref(new DocumentService())
    const state = reactive({
      nombre: '',
      fechaApertura: '',
      fechaCierre: '',
      comentario: '',
      lugarEjecucion: '',
      tipoRadicacion: '',
      valorPresupuesto: 0
    })
    const rules = {
      // eslint-disable-next-line
      nombre: {required},
      fechaApertura: { required },
      fechaCierre: { required },
      comentario: { required },
      lugarEjecucion: { required },
      tipoRadicacion: { required },
      valorPresupuesto: { required, numeric },
      idEstado: { required }
    }
    const submitted = ref(false)
    const countries = ref()
    const date = ref()
    const country = ref()
    const modalAddFolder = ref(false)
    const nameFolder = ref('')
    const v$ = useVuelidate(rules, state)
    const tipoRadicaciones = [
      { id: '0', name: 'Fisico' },
      { id: '1', name: 'Virtual' }
    ]
    let formData = new FormData()
    const activeIndex = ref([])
    const modalListarPdfs = ref(false)
    const listaPdfs = ref([])
    const reemplazarPdfBody = ref({
      id: '',
      oldPdf: ''
    })
    const modalPdf = ref(false)
    const rutaConsolidado = ref(null)
    const giros = ref([
      { nombre: '0°', key: '0' },
      { nombre: '90°', key: '90' },
      { nombre: '180°', key: '180' },
      { nombre: '270°', key: '270' }
    ])
    const pageNumber = ref(null)
    const giro = ref(null)
    const pathConsolidadoByDoc = ref(null)
    const pond = ref()
    const url = ref(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/licitaciones/addpdfs`)

    /** methods */
    const actionModalNameFolder = (data) => {
      modalNameFolder.value = !modalNameFolder.value
      if (modalNameFolder.value) {
        updateCarpeta.value.nombre = data.name
        updateCarpeta.value.id = data.id
      }
    }

    const openModalPdf = () => {
      modalPdf.value = !modalPdf.value
    }

    const getDocuments = () => {
      _DocumentService.value.getDocumentAll().then(({ data }) => {
        documents.value = data
        getInfo()
      })
    }
    const getEstados = () => {
      _LicitacionesService.value.getEstados().then(({ data }) => {
        estadosList.value = data
      })
    }

    const getInfo = () => {
      _LicitacionesService.value.getLicitacion(route.params.id).then(({ data }) => {
        activeIndex.value = []
        carpetas.value = []
        state.nombre = data.nombre
        state.fechaApertura = dayjs(data.fechaApertura).format('YYYY-MM-DD')
        state.fechaCierre = dayjs(data.fechaCierre).format('YYYY-MM-DD')
        state.comentario = data.comentarios
        state.lugarEjecucion = data.lugarEjecucion
        state.tipoRadicacion = data.tipoRadicacion.toString()
        state.valorPresupuesto = parseInt(data.valorPresupuesto)
        state.idEstado = data.idEstado
        // si el estado es liquidado o no adjudicado no puede modificar el estado
        if ([4, 5].includes(parseInt(state.idEstado))) disabledListEstados.value = true
        estadosList.value = estadosList.value.filter((x) => x.id >= parseInt(state.idEstado))
        rutaPdf.value = data.adjuntoPliegos
        for (const key in data.Carpetas) {
          const list = []
          for (const i in data.Carpetas[key].CarpetasDocumentos) {
            list.push({
              id: data.Carpetas[key].CarpetasDocumentos[i].documentoId,
              nombre: data.Carpetas[key].CarpetasDocumentos[i].Documento.nombre,
              licCarpetaDocumento: data.Carpetas[key].CarpetasDocumentos[i].id,
              path: data.Carpetas[key].CarpetasDocumentos[i].path
            })
          }
          activeIndex.value.push(parseInt(key))
          carpetas.value.push({
            id: data.Carpetas[key].id,
            name: data.Carpetas[key].nombre,
            list: list
          })
        }

        const myArray = data.Carpetas.map(item => item.CarpetasDocumentos).flat().map(item => item.documentoId)
        documents.value = documents.value.filter(item => !myArray.includes(item.id))
        documentsFiltered.value = documents.value.filter(item => !myArray.includes(item.id))
      })
    }

    const viewPdf = () => {
      _LicitacionesService.value.getPdf(rutaPdf.value).then((url) => {
        window.open(url, '_blank')
      })
    }

    const verListarPdfs = () => {
      modalListarPdfs.value = !modalListarPdfs.value
      _LicitacionesService.value.getListaPdfs(route.params.id).then(({ data }) => {
        listaPdfs.value = data
      })
    }

    const verPdf = (ruta) => {
      if (ruta) {
        pathConsolidadoByDoc.value = ruta
        _LicitacionesService.value.getPdf(ruta).then((url) => {
          rutaConsolidado.value = url
          openModalPdf()
        })
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
      }
    }

    const filterDocuments = () => {
      console.log('filter', filterText.value)
      if (filterText.value) {
        documentsFiltered.value = documents.value.filter(document => document.nombre.toLowerCase().includes(filterText.value.toLowerCase()))
      } else {
        documentsFiltered.value = documents.value
      }
    }

    const hideModalAddFolder = () => {
      modalAddFolder.value = false
    }
    const validationInput = (a, b) => {
      return a && !b
    }
    const saveModalAddFolder = () => {
      if (nameFolder.value) {
        _LicitacionesService.value.crearCarpeta({
          nombre: nameFolder.value,
          licitacionId: route.params.id,
          orden: carpetas.value.length
        }).then(() => {
          modalAddFolder.value = false
          getInfo()
        })
      }
    }
    const handleSubmit = (isFormValid) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      formData.append('nombre', state.nombre)
      formData.append('fechaApertura', state.fechaApertura)
      formData.append('fechaCierre', state.fechaCierre)
      formData.append('comentarios', state.comentario)
      formData.append('lugarEjecucion', state.lugarEjecucion)
      formData.append('tipoRadicacion', state.tipoRadicacion)
      formData.append('valorPresupuesto', state.valorPresupuesto)
      formData.append('idEstado', state.idEstado)
      _LicitacionesService.value.updateLicitaciones(route.params.id, formData).then(({ data }) => {
        router.go('-1')
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
      resetForm()
    }

    const onUpload = async (event) => {
      formData.append('adjuntoPliegos', event.files[0])
      formData.append('oldPdf', reemplazarPdfBody.value.oldPdf)
      _LicitacionesService.value.reemplazarPdf(reemplazarPdfBody.value.id, formData).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(route.params.id).then(({ data }) => {
          listaPdfs.value = data
        })
        formData = new FormData()
        document.getElementById('adjuntoPliegos').value = ''
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const onUploadNuevoPliego = async (event) => {
      formData.append('adjuntoPliegos', event.files[0])
      formData.append('licitacionId', route.params.id)
      _LicitacionesService.value.addPdf(formData).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(route.params.id).then(({ data }) => {
          listaPdfs.value = data
        })
        formData = new FormData()
        document.getElementById('nuevoPliego').value = ''
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const resetForm = () => {
      submitted.value = false
      formData = new FormData()
    }

    const onRowReorder = (event) => {
      let counter = -1
      carpetas.value = event.value
      const newOrden = event.value.map(item => {
        counter++
        return {
          id: item.id,
          orden: counter
        }
      })
      _LicitacionesService.value.reordenarCarpetas({
        licitacionId: route.params.id,
        Carpetas: newOrden
      }).then(({ data }) => {
        if (data.success) {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Orden modificado exitosamente', life: 3000 })
        }
      }).catch((error) => {
        console.log(error)
      })
    }

    const abrio = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder-open')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder')
    }

    const cerro = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder-open')
    }

    const editarCarpeta = () => {
      _LicitacionesService.value.actualizarCarpeta(updateCarpeta.value.id, { nombre: updateCarpeta.value.nombre }).then(({ data }) => {
        if (data.updated) {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Carpeta actualizada con exito', life: 3000 })
          getInfo()
        }
      }).catch((error) => {
        console.log(error)
      })
      modalNameFolder.value = false
    }

    const eliminarCarpeta = ({ id }) => {
      _LicitacionesService.value.eliminarCarpeta(id).then(({ data }) => {
        if (data.deleted) {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Carpeta eliminada con exito', life: 3000 })
          getInfo()
        }
      }).catch((error) => {
        console.log(error)
      })
    }

    const log = (event, id) => {
      if (event.moved) {
        ordenarDocumentos(id)
      }
      if (event.added) {
        if (event.added.element.licCarpetaDocumento) {
          actualizarDocumento(event.added.element.licCarpetaDocumento, id)
        } else {
          crearDocumento(event.added.element.id, id)
        }
      }
      if (event.removed) {
        const existElement = documentsFiltered.value.find(item => item.id === event.removed.element.id)
        if (existElement) {
          Swal.fire({
            title: 'Esta seguro?',
            text: 'Se eliminara permanentemente y se perderan los archivos cargados',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              ordenarDocumentos(id)
            }
          })
        }
      }
    }

    const crearDocumento = (documentoId, id) => {
      _LicitacionesService.value.crearDocumento({
        licitacionId: route.params.id,
        licCarpetaId: id,
        documentoId: documentoId,
        orden: carpetas.value.find(item => item.id === id).list.length
      }).then(({ data }) => {
        if (data) {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Agregado con exito', life: 3000 })
          getInfo()
        }
      }).catch((error) => {
        console.log(error)
      })
    }

    const actualizarDocumento = (elementoId, id) => {
      _LicitacionesService.value.actualizarDocumento(elementoId, {
        licCarpetaId: id
      }).then(({ data }) => {
        if (data.updated) {
          ordenarDocumentos(id)
        } else {

        }
      }).catch((error) => {
        console.log(error)
      })
    }

    const ordenarDocumentos = (id) => {
      _LicitacionesService.value.reordenarDocumentos({
        licitacionId: parseInt(route.params.id),
        licCarpetaId: id,
        CarpetasDocumentos: carpetas.value.find(item => item.id === id).list.map((e, index) => {
          return {
            id: e.licCarpetaDocumento,
            orden: index
          }
        })
      }).then(({ data }) => {
        if (data.success) {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Ordenado con exito', life: 3000 })
          getInfo()
        }
      }).catch((error) => {
        console.log(error)
      })
    }

    const modalListarPdfsBtn = () => {
      console.log(listaPdfs.value)
    }

    const addPdf = () => {
      nuevoPliego.value.click()
    }

    const reemplazarPdf = (data) => {
      adjuntoPliegos.value.click()
      reemplazarPdfBody.value.id = data.id
      reemplazarPdfBody.value.oldPdf = data.path
    }

    const eliminarPdf = (data) => {
      _LicitacionesService.value.eliminarPdf(data.id).then(({ data }) => {
        _LicitacionesService.value.getListaPdfs(route.params.id).then(({ data }) => {
          listaPdfs.value = data
        })
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Actualizado con exito', life: 3000 })
      })
    }

    const girarPdf = () => {
      if (pageNumber.value && giro.value) {
        if (pathConsolidadoByDoc.value) {
          document.getElementById('cargando').style.display = 'flex'
          const embed = document.getElementById('embedPdf')
          const element = document.getElementById('pdf')
          element.parentNode.removeChild(element)
          _LicitacionesService.value.girarPdf({
            pathConsolidado: pathConsolidadoByDoc.value,
            page: pageNumber.value,
            rotate: parseInt(giro.value)
          }).then((url) => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Se giro correctamente', life: 3000 })

            document.getElementById('cargando').style.display = 'none'
            embed.append(element)
            // document.getElementById('pdf').contentDocument = location.reload()
          })
        } else {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tiene documento cargado', life: 3000 })
        }
      } else {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El numero de pagina o cantidad de giro es obligatorio', life: 3000 })
      }
    }

    const pondCargarPdf = () => {
      pond.value.onSubmitSupport().then(async () => {
        _LicitacionesService.value.getListaPdfs(route.params.id).then(({ data }) => {
          listaPdfs.value = data
          pond.value.onRemoveSupport()
        })
      })
    }

    return {
      pond,
      pondCargarPdf,
      girarPdf,
      modalPdf,
      onUploadNuevoPliego,
      nuevoPliego,
      eliminarPdf,
      addPdf,
      reemplazarPdf,
      state,
      v$,
      handleSubmit,
      submitted,
      countries,
      date,
      country,
      carpetas,
      onRowReorder,
      modalAddFolder,
      log,
      documents,
      nameFolder,
      hideModalAddFolder,
      saveModalAddFolder,
      validationInput,
      tipoRadicaciones,
      onUpload,
      filterDocuments,
      filterText,
      documentsFiltered,
      adjuntoPliegos,
      viewPdf,
      abrio,
      cerro,
      activeIndex,
      editarCarpeta,
      modalNameFolder,
      actionModalNameFolder,
      updateCarpeta,
      eliminarCarpeta,
      verPdf,
      modalListarPdfs,
      verListarPdfs,
      listaPdfs,
      modalListarPdfsBtn,
      rutaConsolidado,
      giros,
      pageNumber,
      giro,
      route,
      url,
      estadosList,
      disabledListEstados

    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px;

    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  ::v-deep(.p-accordion .p-accordion-content) {
    background: #cfd4d703;
  }
}
</style>

<style>
.p-dialog-mask{
  z-index: 10000 !important;
}
</style>
